<template>
 <div id="topicdialogContent">
  <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="dialogVisible" @close="closeDialog" class="dialog-wrap" width="9rem">
   <form action>
    <div class="topicInformation diaWrapper">
     <div class="topicHeader">
      主题信息topicInfoEdit.vue
      <i :class="{foldBtn:true,onFold:this.foldShow1}" @click="fold(1)"></i>
     </div>
     <div class="foldWrap" v-show="foldShow1">
      <div class="row">
       <div class="column">
        <p class="yogo-input-name">主题名称</p>
        <input placeholder="请输入主题名称" type="text" value="记忆碎片plus" />
       </div>
       <div class="column">
        <p class="yogo-input-name">难度系数</p>
        <el-rate v-model="rateValue"></el-rate>
       </div>
      </div>
      <div class="row">
       <div class="column">
        <p class="yogo-input-name">游戏类型</p>
        <input placeholder="请选择游戏类型" type="text" value="机械解密" />
        <i class="btnicon btnimgdown"></i>
       </div>
       <div class="column">
        <p class="yogo-input-name">游戏风格</p>
        <input placeholder="请选择游戏风格" type="text" value="剧情" />
        <i class="btnicon btnimgdown"></i>
       </div>
      </div>
      <div class="row">
       <div class="column">
        <p class="yogo-input-name">开场最低人数</p>
        <input placeholder="请输入开场最低人数" type="text" value="2" />
       </div>
       <div class="column">
        <p class="yogo-input-name">包场人数</p>
        <input placeholder="请输入包场人数" type="text" value="10" />
       </div>
      </div>
      <div class="row">
       <div class="column">
        <p class="yogo-input-name">建议人数</p>
        <input placeholder="请输入建议人数" type="text" value="10" />
       </div>
       <div class="column">
        <p class="yogo-input-name">门店</p>
        <input placeholder="请选择门店" type="text" value="YOGO优果真人密室逃脱(江汉路店)" />
        <i class="btnicon btnimgdown"></i>
       </div>
      </div>
      <div class="row">
       <div class="column uploadImg columndesc" style=" width: 100%;">
        <p class="yogo-input-name">主题图片</p>

        <input placeholder="请选择图片" type="text" value />
        <button class="el-button topicimgbtn" type="button">
         <i></i>浏&nbsp;&nbsp;览
        </button>
        <br />

        <img alt class="topicImg" src="../../statics/images/scanImg.png" />
       </div>
      </div>
      <div class="row">
       <div class="column columndesc">
        <p class="yogo-input-name">门店介绍</p>
        <textarea placeholder="请输入门店介绍" type="text" valign="top"></textarea>
       </div>
      </div>
     </div>
    </div>
    <div class="sessionSetting diaWrapper">
     <div class="topicHeader">
      场次设置
      <i :class="{foldBtn:true,onFold:this.foldShow2}" @click="fold(2)"></i>
     </div>
     <div class="foldWrap" v-show="foldShow2">
      <div class="row">
       <div class="column">
        <p class="yogo-input-name">营业时段</p>
        <div class="runTime">
         <div class="runStart">
          <div>
           <input type="text" value="10" />
           <i class="btnicon btnimgdown"></i>
          </div>
          <span>:</span>
          <div>
           <input type="text" value="30" />
           <i class="btnicon btnimgdown"></i>
          </div>
         </div>
         <span>至</span>
         <div class="runEnd">
          <div>
           <input type="text" value="22" />
           <i class="btnicon btnimgdown"></i>
          </div>
          <span>:</span>
          <div>
           <input type="text" value="30" />
           <i class="btnicon btnimgdown"></i>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div class="row">
       <div class="column">
        <p class="yogo-input-name">场次时段</p>
        <div class="sessionTime">
         <input type="text" value="10:00" />
         <span>-</span>
         <input type="text" value="11:00" />
         <button type="button"></button>
        </div>
       </div>
      </div>
      <div class="row">
       <button class="addBtn" type="button">+添加场次</button>
      </div>
     </div>
    </div>
    <div class="priceSetting diaWrapper">
     <div class="topicHeader">
      票价设置
      <i :class="{foldBtn:true,onFold:this.foldShow3}" @click="fold(3)"></i>
     </div>
     <div class="foldWrap" v-show="foldShow3">
      <div class="branch-1">
       <div class="branchHeader">定价规律</div>
       <div class="branchWrapper">
        <p>
         <span>日期规格</span>
         <span>时段规格</span>
        </p>
        <div>
         <div class="branchLeft column">
          <div>
           <input type="text" value="周一" />
           <i class="btnicon btnimgdown"></i>
          </div>
          <span>-</span>
          <div>
           <input type="text" value="周日" />
           <i class="btnicon btnimgdown"></i>
          </div>
         </div>
         <div class="branchRight">
          <div class="Period">
           <el-radio label="1" v-model="radio">不分时段定价</el-radio>
           <el-radio label="2" v-model="radio">分时段定价</el-radio>
          </div>
          <div class="sessionTime">
           <p class="sTHeader">
            <span>场次开始时间</span>
            <span>场次结束时间</span>
           </p>
           <ul class="sessionList">
            <li class="sessionItem">
             <div class="column">
              <input type="text" value="10:30" />
              <i class="btnicon btnimgdown"></i>
             </div>
             <span>-</span>
             <div class="column">
              <input type="text" value="14:30" />
              <i class="btnicon btnimgdown"></i>
             </div>
             <button type="button"></button>
            </li>
            <li class="sessionItem">
             <div class="column">
              <input type="text" value="10:30" />
              <i class="btnicon btnimgdown"></i>
             </div>
             <span>-</span>
             <div class="column">
              <input type="text" value="14:30" />
              <i class="btnicon btnimgdown"></i>
             </div>
             <button type="button"></button>
            </li>
           </ul>
           <button class="addBtn" type="button">+添加分段</button>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div class="branch-2">
       <div class="branchHeader">售卖价格</div>
       <div class="branchWrapper">
        <p>
         <span>日期规格</span>
         <span>时段规格</span>
         <span>单人价格</span>
        </p>
        <ul>
         <li>周一-周日</li>
         <li>
          <div>10:30-20:00之间的场次</div>
          <div>10:30-20:00之间的场次</div>
         </li>
         <li>
          <div>
           <input type="text" value="139.00" />元/人
          </div>
          <div>
           <input type="text" value="139.00" />元/人
          </div>
         </li>
        </ul>
       </div>
      </div>
     </div>
    </div>
   </form>
   <div class="row rowbuttons">
    <el-button @click="closeDialog" type="primary">确 定</el-button>
    <el-button @click="closeDialog">取 消</el-button>
   </div>
   <!-- <span slot="footer" class="dialog-footer"></span> -->
  </el-dialog>
 </div>
</template>

<script>
export default {
 data() {
  return {
   dialogVisible: this.inDialogVisible,
   deleteShow: false,
   rateValue: 3,
   radio: '2',
   foldShow1: true,
   foldShow2: false,
   foldShow3: false,
   title: '新增主题1111',
   callback: null,
  }
 },
 methods: {
  closeDialog() {
   this.dialogVisible = false
   this.callback = null
  },
  showDialog(title = '新增公司', data, callback) {
   this.dialogVisible = true
   //  this.title = title
   this.title = 'sdfsdf'
   if (data) {
    this.currObj = data
   } else {
    this.currObj = {}
   }
   this.callback = callback
  },
  fold(part) {
   if (part === 1) {
    this.foldShow1 = !this.foldShow1
    return
   } else if (part === 2) {
    this.foldShow2 = !this.foldShow2
    return
   } else {
    this.foldShow3 = !this.foldShow3
   }
  },
 },
}
</script>

<style lang="less">
#topicdialogContent {
 //  border: solid 11px red;
 border-radius: 5px;
 box-sizing: border-box;
 overflow-x: hidden;

 .row {
  display: flex;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 0.1rem;

  &:last-of-type {
   margin-bottom: 0;
  }
 }

 .rowbuttons {
  justify-content: center;
 }

 .column {
  position: relative;
  width: 2.1rem;
  margin-right: 15px;

  input {
   width: 2.1rem;
   font-size: 0.1rem;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   height: 0.3rem;
   text-indent: 8px;
  }

  input[type='radio'] {
   width: 0.16rem;
   height: 0.16rem;
   line-height: 0.16rem;
   vertical-align: middle;
   margin-left: 5px;
  }

  input::-webkit-input-placeholder {
   color: #b1b1b1;
  }

  .yogo-input-name {
   color: #000000;
   font-size: 1.2em;
   padding-bottom: 0.1rem;
  }

  .radios {
   display: flex;
   color: #b1b1b1;
   width: 100%;

   label {
    width: 25%;
   }
  }

  .btnicon {
   position: absolute;
   right: 0px;
   height: 0.3rem;
   width: 0.3rem;
   background: #d2d2d2 url(../../statics/images/icondown2.png) center center no-repeat;
   border-radius: 0 5px 5px 0;
  }

  .btnimgdown {
   background-image: url(../../statics/images/icondown2.png);
   background-size: 0.12rem 0.08rem;
  }

  .btnimgdate {
   background-image: url(../../statics/images/icondate2.png);
   background-size: 0.16rem 0.15rem;
  }
 }

 .columndesc {
  textarea {
   font-size: 1.1em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   width: 4.35rem;
   height: 1rem;
   text-indent: 8px;
  }
 }

 .buttons {
  text-align: center;
  margin-bottom: 0;
  width: 3.22rem;
  margin: 0 auto;
 }

 .el-dialog__header {
  background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat;
  background-size: 7rem 0.4rem;
  width: 7rem;
  height: 0.4rem;
  position: relative;
  padding: 0;

  .el-dialog__title {
   font-size: 1.5em;
   line-height: 0.4rem;
   float: left;
   margin-left: 0.4rem;
   color: white;
   font-weight: normal;
  }

  &::before {
   content: '';
   display: block;
   width: 0.24rem;
   height: 0.21rem;
   background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
   background-size: 0.24rem 0.21rem;
   margin: 0.1rem;
   position: absolute;
  }

  .el-dialog__headerbtn {
   width: 0.29rem;
   height: 0.29rem;
   background-color: #fff;
   top: 0.06rem;
   right: -0.48rem;
   border-radius: 50%;

   i::before {
    font-size: 28px;
    // line-height: 29px;
   }
  }
 }

 .el-dialog[aria-label='修改主题'],
 .el-dialog[aria-label='新增主题'] {
  .el-dialog__header {
   background: url('../../statics/images/other/标题栏BG@2x.png') no-repeat center;
   background-size: 7rem 0.4rem;
   width: 7rem;
   height: 0.4rem;
   position: relative;
   padding: 0;

   &::before {
    content: '';
    display: block;
    width: 0.24rem;
    height: 0.21rem;
    background: url('../../statics/images/other/yogo@2x.png') no-repeat center;
    background-size: 0.24rem 0.21rem;
    margin: 0.1rem;
    position: absolute;
   }

   .el-dialog__headerbtn {
    width: 0.28rem;
    height: 0.29rem;
    background-color: #fff;
    top: 0.06rem;
    right: -0.48rem;
    border-radius: 50%;

    i::before {
     font-size: 0.3rem;
     // line-height: 29px;
    }
   }
  }
 }

 .columndesc {
  width: 100%;

  textarea {
   // font-size: 1.23em;
   color: #b1b1b1;
   border: 1px solid #d2d2d2;
   border-radius: 5px;
   width: 5.91rem;
   height: 1.2rem;
   text-indent: 8px;
  }
 }

 .el-dialog__body {
  border: solid 1px red;
  padding: 30px;
  // padding-bottom: 0;
  overflow-y: auto;

  .topicimgbtn {
   background: #208def;
   color: #fff;
   margin-left: 0.3rem;
   border: none;
   width: 1.06rem;
   height: 0.36rem;
   border-radius: 6px;

   i {
    padding: 0 5px;
   }

   i::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0.16rem;
    height: 0.16rem;
    background: url('../../statics/images/iconaddtopic.png') no-repeat;
    background-size: 0.16rem 0.16rem;
   }
  }
 }

 .diaWrapper {
  width: 6.5rem;
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  padding: 30/100rem;
  padding-bottom: 0;
  margin-bottom: 0.4rem;
  background: #f1f1f1;

  .topicHeader {
   font-size: 22/100rem;
   padding-left: 6px;
   background: url('../../statics/images/矩形 1 拷贝 9@2x.png') no-repeat 0 center;
   background-size: 4/100rem 22/100rem;
   line-height: 22/100rem;
   margin-bottom: 0.3rem;
   color: #000;

   i.foldBtn {
    float: right;
    background: url('../../statics/images/icon-up.png') no-repeat center;
    width: 0.14rem;
    height: 0.08rem;
    background-size: contain;
    margin-top: 0.06rem;
    cursor: pointer;
   }

   .onFold {
    transform: rotate(180deg);
   }
  }
 }

 .addBtn {
  width: 1.18rem;
  height: 0.4rem;
  border: 1px solid #208def;
  color: #208def;
  font-size: 0.18rem;
  border-radius: 6px;
  background-color: #fff;
  margin-top: 0.3rem;
 }

 .topicInformation {
  .topicImg {
   display: inline-block;
   margin-top: 10px;
  }
 }

 .sessionSetting {
  .row {
   .column {
    width: 100%;

    span {
     width: 0.3rem;
     color: #818181;
     font-size: 0.16rem;
     text-align: center;
     height: 0.36rem;
     line-height: 0.36rem;
    }

    .runTime {
     display: flex;

     > div {
      //   width: 50%;
      display: flex;

      > div {
       position: relative;
       width: 1.25rem;
      }
     }

     input {
      width: 1.25rem;
     }
    }

    div.sessionTime {
     display: flex;

     input {
      width: 2.48rem;
     }

     button {
      width: 0.36rem;
      height: 0.36rem;
      background: #e66969 url('../../statics/images/delete.png') no-repeat center center;
      background-size: 0.16rem 0.18rem;
      border-radius: 6px;
      margin-left: 0.3rem;
     }
    }
   }
  }
 }

 .priceSetting {
  .branchHeader {
   font-size: 0.18rem;
   line-height: 0.18rem;
   color: #000000;
   margin-bottom: 0.19rem;
  }

  .branch-2 {
   margin-top: 0.3rem;

   .branchWrapper {
    > p {
     span {
      width: 33%;
      flex: 1;
     }
    }

    ul {
     display: flex;
     align-items: center;

     > li {
      // flex: 1;
      width: 33.3%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      color: #818181;
      font-size: 0.16rem;
      box-sizing: border-box;

      &:nth-of-type(2) {
       padding: 0.5rem;

       > div {
        width: 1rem;
        text-align: center;
        height: 0.38rem;
        line-height: 0.19rem;

        &:not(:last-of-type) {
         margin-bottom: 0.18rem;
        }
       }
      }

      input {
       width: 0.61rem;
       border: 1px solid rgba(177, 177, 177, 1);
       height: 0.36rem;
       border-radius: 6px;
       text-indent: 0.08rem;
       margin-right: 0.09rem;
      }

      &:nth-of-type(3) {
       > div:not(:last-of-type) {
        margin-bottom: 0.21rem;
       }
      }
     }
    }
   }
  }

  .branchWrapper {
   border: 1px solid rgba(177, 177, 177, 1);
   border-radius: 6px;

   > p {
    display: flex;
    width: 100%;
    align-items: center;
    height: 0.57rem;
    background-color: #d6d6d6;

    span {
     font-size: 0.18rem;
     width: 50%;
     text-align: center;
    }
   }

   > div {
    display: flex;
    height: 3.32rem;

    > div {
     width: 2.67rem;
     margin-right: 0;
    }

    div.branchRight {
     flex: 1;
     margin: 0 0.3rem 0 0rem;

     div.Period {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.3rem 0;

      > label {
       display: flex;
       align-items: center;
       border-radius: 50% !important;
       height: 0.17rem;

       &:first-of-type {
        margin-right: 0.1rem;
       }
      }

      .el-radio__input {
       display: flex;

       .el-radio__inner {
        width: 0.17rem;
        height: 0.17rem;
        border: 1px solid #bebebe;

        &:after {
         background-color: #8d8d8d;
         width: 0.1rem;
         height: 0.1rem;
         transform: translate(-43%, -51%) scale(0);
        }
       }

       + .el-radio__label {
        color: #878787;
        font-size: 0.16rem;
        padding-left: 5/100rem;
       }
      }

      .el-radio__input.is-checked {
       .el-radio__inner {
        background: #fff;
        border-color: #bebebe;

        &:after {
         transform: translate(-43%, -51%) scale(1);
        }
       }

       + .el-radio__label {
        color: #878787;
       }
      }
     }

     div.sessionTime {
      margin-top: 0.3rem;
      padding: 0.2rem;
      background-color: #eeeeee;
      border-radius: 6px;

      .addBtn {
       margin-top: 0;
      }

      > p.sTHeader {
       font-size: 16/100rem;
       line-height: 0.16rem;
       margin-bottom: 0.2rem;
       display: flex;

       span {
        margin-right: 0.26rem;
       }
      }

      .sessionList {
       .sessionItem {
        display: flex;
        margin-bottom: 0.2rem;

        .column {
         width: 0.89rem;
         margin: 0;

         input {
          width: 0.89rem;
          font-size: 0.14rem;
         }
        }

        span {
         width: 0.3rem;
         text-align: center;
         line-height: 0.36rem;
        }

        button {
         width: 0.36rem;
         height: 0.36rem;
         background: #e66969 url('../../statics/images/delete.png') no-repeat center center;
         background-size: 0.16rem 0.18rem;
         border-radius: 6px;
         margin-left: 0.1rem;
        }
       }
      }
     }
    }

    div.branchLeft {
     display: flex;
     align-items: center;
     justify-content: center;

     > div {
      position: relative;
      width: 0.82rem;
     }

     input {
      width: 0.82rem;
     }

     span {
      width: 0.3rem;
      text-align: center;
      font-size: 0.16rem;
      color: #818181;
     }
    }
   }
  }
 }
}
</style>
